import Swal from 'sweetalert2'
$(document).on('turbolinks:load', function () {

  const observer = lozad('.lazyload', {
    loaded: function (el) {
        el.removeAttribute('data-src');
        el.classList.remove('loading');
        el.classList.add('loaded');
        el.previousElementSibling.style.display = 'none'; // Hide the loading spinner
    },
  });
  observer.observe();

  // new result registered popup

  if (document.getElementById('show-message')) {
    // getting values
    var eventType = document.getElementById('event_type').innerHTML
    var revenue = document.getElementById('result_revenue').innerHTML
    if (['one_time', 'monthly'].includes(eventType) || revenue <= 0){
      var title = document.getElementById('popup-container-great-success').innerHTML
    }else{
      var title = document.getElementById('popup-container-exiting-news').innerHTML
    }

    Swal.fire({
      title: title,
      html: get_context(),
      confirmButtonColor: '#4d7de1',
      cancelButtonColor: '#DB4437',
      showConfirmButton: false, // Set to false to hide the default confirmation button
      allowOutsideClick: false, // Prevent closing by clicking outside the popup
      customClass: {
        popup: 'new-result-swal'
      },
      onOpen: (modalElement) => {
        // Attach event listeners to your custom buttons
        document.getElementById('closeButton').addEventListener('click', () => {
          Swal.close();
        });
      },
    });
  }

  function openSweetAlert(id, amount, sportpengen_amount, admin_mail) {
    console.log(id);
    console.log("sportpengen_amount >>>>>>" + sportpengen_amount);
    console.log("amount >>>>>>" +amount);
    console.log("admin mail >>>>>>" + admin_mail);
    // Open SweetAlert2 modal with custom HTML content
    Swal.fire({
        title: `${I18n.t('result_refund.result_popup.title')}`,
        html:
            `<form id="swalForm" style="padding: 1rem;">
                <div class="dropdown_div">
                    <label for="swalDropdown">${I18n.t('result_refund.result_popup.context1')}</label>
                    <select class="form-control" id="swalDropdown" required>
                        <option style="color: grey;" value="" disabled selected>${I18n.t('result_refund.result_popup.refund_reason')}</option>
                        <option value="duplicate">${I18n.t('result_refund.result_popup.duplicate')}</option>
                        <option value="fraudulent">${I18n.t('result_refund.result_popup.fraudulent')}</option>
                        <option value="my_mistake">${I18n.t('result_refund.result_popup.my_mistake')}</option>
                        <option value="request_by_backer">${I18n.t('result_refund.result_popup.request_by_backer')}</option>
                        <option value="other">${I18n.t('result_refund.result_popup.other')}</option>
                        <option value="sportpengen_bug">${I18n.t('result_refund.result_popup.sportpengen_bug')}</option>
                        <!-- Add more options as needed -->
                    </select>
                </div>
                <div style="margin-top: 10px;">
                    <label for="swalTextInput"></label>
                    <textarea class="form-control" id="swalTextInput" rows="4" required placeholder="${I18n.t('result_refund.result_popup.message_to_backers_placeholder')}"></textarea>
                </div>
                <div id="general_context" style="display: block;">
                    <p class="mt-4">${I18n.t('result_refund.result_popup.normal_context1', { amount: amount })}</p>
                    <p>${I18n.t('result_refund.result_popup.normal_context2')}</p>
                    <p class="mt-4" style="font-weight: 500; margin-bottom: -20px;">${I18n.t('result_refund.result_popup.normal_context3')}</p>
                </div>

                <div id="bug_context" style="display: none;">
                    <p class="mt-4">${I18n.t('result_refund.result_sportpengen_popup.sportpengen_context1')}</p>
                    <label for="swalTextInputMessage"></label>
                    <textarea class="form-control" id="swalTextInputMessage" rows="4" required placeholder="${I18n.t('result_refund.result_sportpengen_popup.message_to_backers')}"></textarea>
                    <p class="mt-2">${I18n.t('result_refund.result_sportpengen_popup.sportpengen_context2')}</p>
                    <p>${I18n.t('result_refund.result_sportpengen_popup.sportpengen_context3', {admin_mail: admin_mail})}</p>
                    <p>${I18n.t('result_refund.result_sportpengen_popup.sportpengen_context4', {amount: sportpengen_amount})}</p>
                    <p class="mt-4" style="font-weight: 500; margin-bottom: -20px;">${I18n.t('result_refund.result_sportpengen_popup.sportpengen_context5')}</p>
                </div>
                
                <div id="imageSelector" style="margin-top: 10px; display: none;">
                    <label for="swalImageInput">Select an image:</label>
                    <input type="file" class="form-control" id="swalImageInput">
                </div>
            </form>`,
        showCancelButton: true,
        confirmButtonColor: '#4d7de1',
        cancelButtonColor: '#DB4437',
        confirmButtonText: `${I18n.t('result_refund.result_popup.button')}`,
        cancelButtonText: `${I18n.t('sponsorships.cancel')}`,
        showLoaderOnConfirm: true,
        onBeforeOpen: () => {
            // Add change event listener to the dropdown
            document.getElementById('swalDropdown').addEventListener('change', function () {
                // Get the selected option value
                const selectedOption = this.value;

                // Toggle the visibility of the image selector based on the selected option
                const imageSelector = document.getElementById('imageSelector');
                const general_context = document.getElementById('general_context');
                const bug_context = document.getElementById('bug_context');
                var swalTextInput = document.getElementById('swalTextInput');
                var confirm_button = document.querySelector('.swal2-confirm');

                if (selectedOption === 'sportpengen_bug') {
                    // imageSelector.style.display = 'block';
                    general_context.style.display = 'none';
                    bug_context.style.display = 'block';
                    swalTextInput.placeholder = `${I18n.t('result_refund.result_sportpengen_popup.message_to_sportpengen')}`;
                    confirm_button.innerHTML = `${I18n.t('result_refund.result_sportpengen_popup.button')}`;
                } else {
                    // imageSelector.style.display = 'none';
                    general_context.style.display = 'block'
                    bug_context.style.display = 'none';
                    swalTextInput.placeholder = `${I18n.t('result_refund.result_popup.message_to_backers_placeholder')}`;
                    confirm_button.innerHTML = `${I18n.t('result_refund.result_popup.button')}`;
                }
            });
        },
        preConfirm: () => {
            // Get form values from the SweetAlert2 modal
            const swalDropdownValue = document.getElementById('swalDropdown').value;
            const swalTextInputValue = document.getElementById('swalTextInput').value;
            const swalTextInputMessageValue = document.getElementById('swalTextInputMessage').value;
            const swalImageInputValue = document.getElementById('swalImageInput') ? document.getElementById('swalImageInput').value : null;

            // Check if both dropdown and text input are filled
            const isSwalFormFilled = swalDropdownValue.trim() !== '' && swalTextInputValue.trim() !== '';

            if (!isSwalFormFilled) {
                Swal.showValidationMessage(`${I18n.t('result_refund.result_popup.error_message')}`);
            }

            return { isSwalFormFilled, swalDropdownValue, swalTextInputValue, swalImageInputValue, swalTextInputMessageValue };
        },
        allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
        // Redirect to a new URL if the user clicked OK and the form is valid
        if (result.value.isSwalFormFilled) {
            // Use encodeURIComponent to handle special characters in the URL parameters
            const encodedDropdownValue = encodeURIComponent(result.value.swalDropdownValue);
            const encodedTextInputValue = encodeURIComponent(result.value.swalTextInputValue);
            const encodedTextInputMessageValue = encodeURIComponent(result.value.swalTextInputMessageValue);
            const encodedImageInputValue = result.value.swalImageInputValue ? encodeURIComponent(result.value.swalImageInputValue) : '';

            // Replace the following line with your actual redirect logic
            window.location.href = `/refund_submit?dropdown=${encodedDropdownValue}&textInput=${encodedTextInputValue}&imageInput=${encodedImageInputValue}&id=${id}&message=${encodedTextInputMessageValue}`;
        }
    });
}


  function openSweetAlertForDeletion(id) {
    console.log(id);
    // Open SweetAlert2 modal with custom HTML content
    Swal.fire({
        title: `${I18n.t('result_refund.result_deletion_popup.title')}`,
        html:
            `<div class="mt-3">
                <p>${I18n.t('result_refund.result_deletion_popup.context1')}</p>
                <p>${I18n.t('result_refund.result_deletion_popup.context2')}</p>
            </div>`,
        showCancelButton: true,
        confirmButtonColor: '#4d7de1',
        cancelButtonColor: '#DB4437',
        confirmButtonText: `${I18n.t('result_refund.result_deletion_popup.button')}`,
        cancelButtonText: `${I18n.t('sponsorships.cancel')}`,
        showLoaderOnConfirm: true,
        preConfirm: () => {
            // You can add any pre-processing logic here if needed
        },
        allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if(result.value == true){
        window.location.href = `/refund_submit?id=${id}`;
      }
    });
}



  // Get all elements with class .CanelResult
  const cancelButtons = document.querySelectorAll('.CanelResult');

  // Loop through each button and attach the event listener
  cancelButtons.forEach((button) => {
    const dataValue = button.getAttribute('data-value');
    const amount = button.getAttribute('data-amount');
    const sportpengen_amount = button.getAttribute('data-sportpengen-amount');
    const admin_mail = button.getAttribute('data-team_admin_mail');
      if(dataValue == 'true'){
        button.addEventListener('click', () => openSweetAlert(button.id, amount, sportpengen_amount, admin_mail));
      }else{
        button.addEventListener('click', () => openSweetAlertForDeletion(button.id));
      }
  });

  // const firstButton = document.querySelector('.CanelResult');
  // firstButton.click();
  
  function get_context(){

    var context1 = document.getElementById('popup-container-result_popup_context1').innerHTML
    var context2 = document.getElementById('popup-container-result_popup_context2').innerHTML
    var context3 = document.getElementById('popup-container-result_popup_context3').innerHTML
    
    var lump1 = document.getElementById('popup-container-result_popup_lumpsum1').innerHTML
    var lump2 = document.getElementById('popup-container-result_popup_lumpsum2').innerHTML

    var button_text = document.getElementById('popup-container-follow-transactions').innerHTML
    var url_id = document.getElementById('show-message').innerHTML
    var eventType = document.getElementById('event_type').innerHTML
    var redirectionMode = null; // Default value is null
    var revenue = document.getElementById('result_revenue').innerHTML

    var redirectionModeElement = document.getElementById('redirection_mode');
    if (redirectionModeElement) {
        redirectionMode = redirectionModeElement.innerHTML;
    } else {
        redirectionMode = '';
    }
    if (['one_time', 'monthly'].includes(eventType) || revenue <= 0){
      return '<div style="margin-top: 1.3rem;">' + 
      '<p>' + lump1 +'</p>' + 
      '<p>' + lump2 +'</p>' + 
      '<button id="closeButton"><i class="fa fa-times" aria-hidden="true"></i></button>'+
      '</div>'
    }else{
      return '<div style="margin-top: 1.3rem;">' + 
      '<p>' + context1 +'</p>' + 
      '<p>' + context2 +'</p>' + 
      '<p>' + context3 +'</p>' + 
      '<button id="closeButton"><i class="fa fa-times" aria-hidden="true"></i></button>'+
      '<a href="/follow_transactions/'+ url_id +'?mode='+ redirectionMode +'" id="redirectButton" style="margin-top: 32px; line-height: 25px; padding: 10px;" class="btn custom-btn btn-primary">' + button_text +'   <i class="fa-solid fa-wallet" style="color: #ffffff; margin-left: 10px;"></i></a>' + 
      '</div>'
    }

  }

  // ------------------------

  if(document.getElementById('account_connect_message')){
    show_popup(document.getElementById('account_connect_message').innerHTML);
  }

  function show_popup(message){
    Swal.fire({
      position: "top-end",
      icon: "info",
      title: message,
      showConfirmButton: true, // Set to true to show the close button
      timer: 15000,
      // width: 300, // Set the width according to your preference
      customClass: {
        title: "swal2-one-line", // Add a custom class for styling
      },
      toast: true, // Display as a toast (one line)
    });
  }

  var elements = document.getElementsByClassName('account_connect_message');

  var messages = [];

  // Extract messages from elements and push them into the messages array
  for (var i = 0; i < elements.length; i++) {
      messages.push(elements[i].innerHTML);
  }

  // Join all messages into one string
  var allMessages = messages.join('<br><hr>');

  if(elements.length > 0){
    // Show SweetAlert popup with a list of messages
    Swal.fire({
      html: allMessages,
      position: "top-end",
      icon: "none",
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        popup: 'custom-popup-class'
      },
      toast: true,
    });
  }

  // Select the elements with the specified class
  var elements = document.querySelectorAll('.custom-popup-class .swal2-header');

  // Loop through each selected element
  elements.forEach(function(element) {
      // Override the flex-direction property
      element.style.flexDirection = 'row-reverse';
  });

  // function showPopupsSequentially(index) {
  //   if (index < elements.length) {
  //     show_team_popup(elements[index].innerHTML, function () {
  //       // Callback function to show the next popup after the current one is closed
  //       showPopupsSequentially(index + 1);
  //     });
  //   }
  // }

  // if (elements.length > 0) {
  //   showPopupsSequentially(0);
  // }

  // function show_team_popup(message, callback) {
  //   Swal.fire({
  //     position: "top-end",
  //     icon: "info",
  //     title: message,
  //     showConfirmButton: true,
  //     customClass: {
  //       title: "swal2-one-line",
  //     },
  //     toast: true,
  //   }).then(function () {
  //     // This callback will be triggered when the user closes the popup
  //     if (typeof callback === 'function') {
  //       callback();
  //     }
  //   });
  // }

  $('.association-form').submit(function() {
    var selectedSports = $('#sports_select_id').val();

    if (!selectedSports || selectedSports.length === 0) {
      show_error_message(I18n.t('associations.flash_messages.select_sport'));
      return false; // prevent form submission
    }

    // Continue with form submission if sports are selected
    return true;
  });

  $('.step3-form').submit(function(event) {
    var fileInput = $('#association_documents')[0];

    if (!fileInput.files.length) {
      show_error_message(I18n.t('associations.flash_messages.attach_document'));
      event.preventDefault(); // Prevent form submission
    }

    // Calculate the total size of selected files
    var totalSize = Array.from(fileInput.files).reduce((acc, file) => acc + file.size, 0);

    // Check if the total size exceeds 5 megabytes
    if (totalSize > 5 * 1024 * 1024) {
      show_error_message(I18n.t('associations.flash_messages.limit_size_error'));
      return false;
    }
  });

  function show_error_message(message)
  {
    Swal.fire({
      icon: "error",
      title: (I18n.t('associations.layout.error')),
      text: message
    });
  }

  var remove_level_buttons = document.getElementsByClassName('remove_fields dynamic')  // button to remove

  // loop through the buttons collection and remove the first delete level button
  if(document.getElementsByClassName('event-amount').length <= 1){
    for (var i = 0; i < remove_level_buttons.length; i++) {
      // remove_level_buttons.item(i).style.display = 'none'
   }
  }
  
  $('.qr-btn').on('click', function (e) {
    let url = e.target.id;
    if(url){
      Swal.fire({
      title: document.getElementById('event_loading_message_text').innerHTML,
      imageUrl: url,
      imageWidth: 400,
      imageHeight: 400,
      imageAlt: 'Custom image',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: document.getElementById('event_download_text').innerHTML,
      cancelButtonText: document.getElementById('event_close_text').innerHTML
    }).then((result) => {
      if (result.value) {
        var a = $("<a>").attr("href", url).attr("download", "Sportpengen_QRcode.png").appendTo("body");
        a[0].click();
        a.remove();
        Swal.fire(
          document.getElementById('event_downloading_text').innerHTML,
          `<div class="qr-downloading-popup">
            ${document.getElementById('event_downloading_message_text').innerHTML}
            <div style="margin-top: 1.5rem; margin-bottom: 1.5rem; width: 2.5rem; height: 2.5rem;" class="loading-spinner-big"></div>
          </div>
          `
        )

        var swal_popup = document.querySelector('.swal2-popup');
        if (swal_popup) {
          swal_popup.classList.add('info-box');
        }
      }
    })

    var swal_popup = document.querySelector('.swal2-popup');
        if (swal_popup) {
          swal_popup.classList.add('info-box');
        }
        
    document.getElementsByClassName('swal2-confirm swal2-styled')[0].style = 'border-radius:2rem; background: #4d7de1;'
    document.getElementsByClassName('swal2-cancel swal2-styled')[0].style = 'border-radius:2rem; background: #dc3545;'
    var img = document.getElementsByClassName('swal2-image')[0]
    img.onload = function() {
      $('#swal2-title').fadeOut('fast');
    }
    document.getElementsByClassName('swal2-image')[0].style.height = "100%"
    document.getElementsByClassName('swal2-image')[0].style.width = "100%"
  }
  });

  $('.cancel-event-button').on('click', function (event) {
    event.preventDefault();
    const event_id = $(this).data('event-id');

    Swal.fire({
      title: $(this).data('confirm-confirm'),
      confirmButtonText: $(this).data('confirm-sign'),
      confirmButtonColor: '#4d7de1',
      cancelButtonColor: '#DB4437',
      showCancelButton: true,
      cancelButtonText: $(this).data('confirm-cancel'),
      html: $(this).data('confirm-heading') + '<br />' + $(this).data('confirm-text'),
      icon: 'warning',
    }).then(function (result) {
      if (result.value) {
        $('#event-box-' + event_id).find('form').submit();
      }
    });

    var swal_popup = document.querySelector('.swal2-popup');
    if (swal_popup) {
      swal_popup.classList.add('info-box');
    }
  })

  $('.fundraiser-btn').on('click', function (e) {

    var calendar = document.getElementById("calendar-group")
    if (this.children[1]) {
      if(this.children[1]['value'] == "monthly") {
        calendar.style.display = "none"
      }
      else {
        calendar.style.display = ""
      }
    } 

    if ($(this).next().is(":hidden")) {
      $('.add_fields')[$(this).index('.fundraiser-btn')].click()
    }
    $('.event_options').not($(this).next()).slideUp();
    $(this).next().slideDown();
    $('.fundraiser-btn').not($(this)).removeClass('active');
    $('.event_options').not($(this).next()).children('.nested-fields').delay(400).remove()
  })
  $('.amount_id_check').on('input',function (e) {
      let team_id = $('.event-form').data('team-id')
      let event_type = $('#hidden-event-type').text();
        $.ajax({
          url: '/' + I18n.currentLocale() + '/calculate_fee/?amount=' + $(this).val() + '&team_id=' + team_id + '&event_type=' + event_type ,
          type: 'GET',
          dataType: 'json',
          error: function (xhr, status, error) {
            console.error('AJAX Error: ' + status + error)
            $('#amount-including-fee').text('')
          },
          success: function (response) {
            $('.amount-with-fee-value small').first().text(response.money + ' ' + I18n.t('events.event_option_fields.amount_with_service_charge'))
          }
        })
    })

  $('.event_options').on('cocoon:after-insert', function (e, insertedElement) {
    $(insertedElement[0]).find('.event-header').on('input', function (e) {
      if ($(e.currentTarget).val().length > 0) {
        $(insertedElement[0]).find('.event-amount').attr('required', true)
        $(insertedElement[0]).find('.event-amount').addClass('required')
      } else {
        if ($(insertedElement[0]).find('.event-description').val().length === 0) {
          $(insertedElement[0]).find('.event-amount').attr('required', false)
          $(insertedElement[0]).find('.event-amount').removeClass('required')
        }
      }
    })
    $(insertedElement[0]).find('.event-amount').on('input', function (e) {
      let team_id = $('.event-form').data('team-id')
      let event_type = $('#hidden-event-type').text();
      $.ajax({
        url: '/' + I18n.currentLocale() + '/calculate_fee/?amount=' + $(this).val() + '&team_id=' + team_id + '&event_type=' + event_type ,
        type: 'GET',
        dataType: 'json',
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error)
          $('#amount-including-fee').text('')
        },
        success: function (response) {
          $(insertedElement[0]).find('.amount-with-fee-value small').text(response.money + ' ' + I18n.t('events.event_option_fields.amount_with_service_charge'))
        }
      })

      if ($(e.currentTarget).val().length > 0) {
        $(insertedElement[0]).find('.event-header').attr('required', true)
        $(insertedElement[0]).find('.event-header').addClass('required')
      } else {
        if ($(insertedElement[0]).find('.event-description').val().length === 0) {
          $(insertedElement[0]).find('.event-header').attr('required', false)
          $(insertedElement[0]).find('.event-header').removeClass('required')
        }
      }
    })
    $(insertedElement[0]).find('.event-description').on('input', function (e) {
      if ($(e.currentTarget).val().length > 0) {
        $(insertedElement[0]).find('.event-header').attr('required', true)
        $(insertedElement[0]).find('.event-header').addClass('required')

        $(insertedElement[0]).find('.event-amount').attr('required', true)
        $(insertedElement[0]).find('.event-amount').addClass('required')
      } else {
        if ($(insertedElement[0]).find('.event-amount').val().length === 0) {
          $(insertedElement[0]).find('.event-header').attr('required', false)
          $(insertedElement[0]).find('.event-header').removeClass('required')
        }

        if ($(insertedElement[0]).find('.event-header').val().length === 0) {
          $(insertedElement[0]).find('.event-amount').attr('required', false)
          $(insertedElement[0]).find('.event-amount').removeClass('required')
        }
      }
    })
  })

  $('.btn-primary.save').on('click', function(){


    $( ".fund-name").each(function (index) {
      if ($(this).val() == ''){
        $(this).focus()
        Swal.fire({
          title: I18n.t('event.negative_amount.name'),
          confirmButtonText: I18n.t('event.negative_amount.confirm'),
          confirmButtonColor: '#4d7de1',
          showCancelButton: false,
          icon: 'warning',
        })
        var swal_popup = document.querySelector('.swal2-popup');
        if (swal_popup) {
          swal_popup.classList.add('info-box');
        }
        returnToPreviousPage();
      }
    });
    $( ".fund-unit").each(function (index) {
      if ($(this).val() == ''){
        $(this).focus()
        Swal.fire({
          title: I18n.t('event.negative_amount.variable'),
          confirmButtonText: I18n.t('event.negative_amount.confirm'),
          confirmButtonColor: '#4d7de1',
          showCancelButton: false,
          icon: 'warning',
        })
        var swal_popup = document.querySelector('.swal2-popup');
        if (swal_popup) {
          swal_popup.classList.add('info-box');
        }
        returnToPreviousPage();
      }
    });

    $( ".fund-date").each(function (index) {
      if ($(this).val() == ''){
        $(this).focus()
        Swal.fire({
          title: I18n.t('event.negative_amount.calender'),
          confirmButtonText: I18n.t('event.negative_amount.confirm'),
          confirmButtonColor: '#4d7de1',
          showCancelButton: false,
          icon: 'warning',
        })
        var swal_popup = document.querySelector('.swal2-popup');
        if (swal_popup) {
          swal_popup.classList.add('info-box');
        }
        returnToPreviousPage();
      }
    });
    $( ".fund-other").each(function (index) {
      if($('#other_check')[0].checked){
        if ($(this).val() == ''){
          $(this).focus()
          Swal.fire({
            title: I18n.t('event.negative_amount.other_text'),
            confirmButtonText: I18n.t('event.negative_amount.confirm'),
            confirmButtonColor: '#4d7de1',
            showCancelButton: false,
            icon: 'warning',
          })
          var swal_popup = document.querySelector('.swal2-popup');
          if (swal_popup) {
            swal_popup.classList.add('info-box');
          }
          returnToPreviousPage();
        }
      }
    });

    $( ".number").each(function( index ) {
      var event_type = document.getElementById('new-event-form-type');
      var check = ["match", "win", "training", "one_time", "monthly", "membership", "training_fee"].includes(event_type.innerHTML);
      if(check){

        if ($(this).val() < 9.99){
          $(this).focus()
          Swal.fire({
            title: I18n.t('event.negative_amount.text'),
            confirmButtonText: I18n.t('event.negative_amount.confirm'),
            confirmButtonColor: '#4d7de1',
            showCancelButton: false,
            icon: 'warning',
          })
          var swal_popup = document.querySelector('.swal2-popup');
          if (swal_popup) {
            swal_popup.classList.add('info-box');
          }
          returnToPreviousPage();
        }
      }else{
        if ($(this).val() <= 0){
          $(this).focus()
          $(this).val(0)
          Swal.fire({
            title: I18n.t('event.negative_amount.zero_text'),
            confirmButtonText: I18n.t('event.negative_amount.confirm'),
            confirmButtonColor: '#4d7de1',
            showCancelButton: false,
            icon: 'warning',
          })
          var swal_popup = document.querySelector('.swal2-popup');
          if (swal_popup) {
            swal_popup.classList.add('info-box');
          }
          returnToPreviousPage();
        }
      }
    })

    // preloader for fundraiser creation
    if(this.value == "'KÖR IGÅNG'" || this.value == "START")
    {
      document.getElementsByClassName('loader')[0].classList.remove("d-none");
    }

    $('.main-form').addClass('d-none')
    $('.choice-about-funding-goal').removeClass('d-none')
    var kr = 'Kr/'
    var last_option_values = {
      goal: kr.concat(I18n.t('helpers.placeholders.event/event_types.goal')),
      match: kr.concat(I18n.t('helpers.placeholders.event/event_types.match')),
      win: kr.concat(I18n.t('helpers.placeholders.event/event_types.win')),
      audience: kr.concat(I18n.t('helpers.placeholders.event/event_types.audience')),
      training: kr.concat(I18n.t('helpers.placeholders.event/event_types.training')),
      monthly: kr.concat(I18n.t('helpers.placeholders.event/event_types.monthly'))
    }
    if ($('.active input').val() == 'one_time'){
      $('#event_funding_goal_type option:last').remove()
    }else{
      $('#event_funding_goal_type option:last').text(last_option_values[$('.active input').val()])
    }
  })

  $('.confirm').on('click', function(){
    $('.choice-about-funding-goal').addClass('d-none')
    $('.add-funding-goal').removeClass('d-none')
  })

  $('.btn-danger.cancel').on('click', function(){
    $('.choice-about-funding-goal').removeClass('d-none')
    $('.add-funding-goal').addClass('d-none')
  })

  $('#new-event-button').hover(function(){
      $(this).css("background-color", "#4d7de1");
      $(this).text(I18n.t('event.links.add_card'));
    }, function(){
      $(this).css("background-color", "grey");
      $(this).text(I18n.t('event.links.new'));
  });


  $('#new-event-result-button').hover(function(){
    $(this).css("background-color", "#4d7de1");
    $(this).text(I18n.t('event.links.add_card'));
  }, function(){
    $(this).css("background-color", "grey");
    $(this).text(I18n.t('event_resultsfo.links.new'));
});

  $('#connect-account-button').hover(function(){
    $(this).css("background-color", "#4d7de1");
    $(this).text(I18n.t('associations.layout.association_request_access'));
  }, function(){
    $(this).css("background-color", "grey");
    $(this).text(I18n.t('associations.layout.association_request_access'));

});
})

